import React from 'react'
import { Link } from 'mf-ui-components'
import styled from 'styled-components'
import { marginBreakpoints, hideBlockAt } from '../../../utils/commonAttrs'
import parser from '../../../utils/htmlParser'
import { graphql, withPrefix } from 'gatsby'
import { sanitiseLink } from '../../../utils/sanitiseLink'

const LinkTag = styled(Link)`
  display: block;
  ${props => marginBreakpoints(props, 20)};
  ${props => hideBlockAt(props)};
`

export default function LinkBlock({
  href,
  inverted,
  role,
  rel,
  size,
  text,
  underline,
  innerLink,
  id,
  marginBottom,
  hiddenAt,
  style,
}) {
  return (
    <LinkTag
      data-preload
      {...{
        id,
        href: sanitiseLink(href, innerLink),
        inverted,
        role,
        rel,
        size,
        text,
        underline,
        marginBottom,
        hiddenAt,
      }}
      style={style}
    >
      {parser(text)}
    </LinkTag>
  )
}

export const query = graphql`
  fragment textLinkFields on WPGraphQL_MfblocksTextLinkBlock {
    name
    attributes {
      id
      marginBottom
      href
      size
      text
      inverted
      rel
      role
      hiddenAt
      underline
      innerLink
    }
  }
`
